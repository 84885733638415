<template>
    <div class="dropdown calendar" @click="dropDownClick" v-click-outside="onClickOutside">
        <div class="selected-options" :class="isDropdownOpen ? 'active' : ''">
            <svg class="calendar-icon" xmlns="http://www.w3.org/2000/svg" width="16" height="17" viewBox="0 0 16 17"
                 fill="none">
                <path d="M10.6665 1.83325V3.83325" stroke="#9A9DAB" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path d="M5.3335 1.83325V3.83325" stroke="#9A9DAB" stroke-width="1.5" stroke-linecap="round"
                      stroke-linejoin="round"/>
                <path
                    d="M2 4.83325C2 3.72868 2.89543 2.83325 4 2.83325H12C13.1046 2.83325 14 3.72868 14 4.83325V13.1666C14 14.2712 13.1046 15.1666 12 15.1666H4C2.89543 15.1666 2 14.2712 2 13.1666V4.83325Z"
                    stroke="#9A9DAB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M2 6.5H14" stroke="#9A9DAB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span class="calendar-title" v-if="!rangeStart || !rangeStart.end">{{ title }}</span>
            <span class="calendar-title"
                  v-else>{{
                    $dayjs(rangeStart.start).format('YYYY-MM-DD')
                }} - {{ $dayjs(rangeStart.end).format('YYYY-MM-DD') }}</span>
        </div>
        <mq-responsive target="tablet+">
            <div class="dropdown-menu" v-show="isDropdownOpen">
                <div @click.stop>
                    <v-date-picker :locale="selectedLanguage"
                                   v-model.range="rangeStart"
                                   :max-date='new Date(lastDate)'/>
                </div>
            </div>
        </mq-responsive>
        <mq-responsive target="mobile">
            <tool-modal-inner :open-modal="isDropdownOpen" v-click-outside="onClickOutside">
                <scrolldatepicker @click.stop
                                  placeholder="October 1, 2023"
                                  v-model="from"
                                  start-period-preset="1W"
                                  :presets="presets"
                                  :range="true"
                                  :theme-color="'#CDCDCD'"
                                  :min="range.min"
                                  :max="lastDate"
                                  :value-from="range.start"
                                  :value-to="range.end"
                                  @input="setValFrom"
                                  @input2="setValTo"
                                  @namedPeriod="setNamedPeriod"
                                  :input-class="'vue-date-input'"
                                  :input-style="{color:'#333333'}"
                />
            </tool-modal-inner>
        </mq-responsive>
    </div>
</template>

<script>
import scrolldatepicker from 'vue-scroll-datepicker-cashbac/components/Scrolldatepicker';
import ToolModalInner from "@/components/Tools/Modal.vue";


export default {
    name: "CalendarDrop",
    components: {
        ToolModalInner,
        scrolldatepicker,
    },
    inject: ["mq"],
    data() {
        return {
            selectedOption: '',
            isDropdownOpen: false,
            rangeStart: {},
            minDate: '2023-10-01',
            lastDate: '2023-12-30',
            modelConfig: {
                start: {
                    timeAdjust: '00:00:00',
                },
                end: {
                    timeAdjust: '23:59:59',
                },
            },

            from: null,
            to: null,
            namedPeriod: null,
            presets: [],
        }
    },
    props: {
        title: {
            type: String,
            default: 'Title'
        },
        range: {
            type: Object,
            required: true
        }
    },
    computed: {
        selectedLanguage(){
            return localStorage.getItem("language") || 'en'
        },
        start(){
            return this.rangeStart?.start
        },
        end(){
            return this.rangeStart.end
        },
        showElementMD() {
            return this.mq.current === "md";
        },
        showElementSM() {
            return this.mq.current === "sm";
        }
    },
    created() {
        this.minDate = this.range.min || '2023-10-01'
        this.lastDate = this.$dayjs().format('YYYY-MM-DD').toString()
        this.setPresets()
    },
    mounted() {
        this.bus.on('clean', this.clean)
    },
    methods: {
        setPresets() {
            let last = this.lastDate;
            let min = this.minDate;

            if (this.$dayjs(last).subtract(1, 'week').format('YYYY-MM-DD') > min) {
                this.presets.push('1W')
            }
            if (this.$dayjs(last).subtract(1, 'month').format('YYYY-MM-DD') > min) {
                this.presets.push('1M')
            }
            if (this.$dayjs(last).subtract(3, 'month').format('YYYY-MM-DD') > min) {
                this.presets.push('3M')
            }
            if (this.$dayjs(last).subtract(6, 'month').format('YYYY-MM-DD') > min) {
                this.presets.push('6M')
            }
            if (this.$dayjs(last).subtract(1, 'year').format('YYYY-MM-DD') > min) {
                this.presets.push('1Y')
            }
        },
        setValFrom(val) {
            this.rangeStart.start = val
            // let start = this.$dayjs(this.rangeStart.start).format('YYYY-MM-DD')
        },
        setValTo(val) {
            this.rangeStart.end = val
            // let end = this.$dayjs(this.rangeStart.end).format('YYYY-MM-DD')
        },
        setNamedPeriod(val) {
            this.namedPeriod = val
        },
        clean() {
            this.rangeStart = {}
        },
        onClickOutside() {
            this.isDropdownOpen = false;
        },
        dropDownClick() {
            this.isDropdownOpen = !this.isDropdownOpen
        },
    },
    watch: {
        start() {
            var utc = require('dayjs/plugin/utc')
            this.$dayjs.extend(utc)
            if(this.start){
                let hour = this.$dayjs().get('hour')
                let min = this.$dayjs().get('minute')
                let seconds = this.$dayjs().get('second')
                let start = this.$dayjs(this.start).set('hour', hour).set('minute', min).set('second', seconds)
                let end = this.$dayjs(this.end).set('hour', hour).set('minute', min).set('second', seconds)
                this.$emit('rangeSetted', this.$dayjs(start).utc().format() +
                    '_' + this.$dayjs(end).utc().format())
            }
        }
    },
}
</script>

<style lang="scss">
.dropdown.calendar {
    .selected-options {
        padding: 9px 14px 9px 10px;
    }

    .selected-options::before {
        display: none;
    }

    .calendar-icon {
        position: absolute;
        width: 16px;
        height: 16px;
    }

    .calendar-title {
        padding-left: 20px;
    }
}

.vc-container {
    border: none !important;
    font-family: Inter Tight !important;
    font-size: 13px;
    font-style: normal;
    line-height: 118%;

    .vc-header {
        .vc-title {
            background: #fff;
        }
    }
}

.vc-arrow {
    border-radius: 16px !important;
    background: rgba(233, 233, 238, 0.50);
    margin-top: -6px !important;
    width: 40px !important;
    height: 40px !important;

    .vc-base-icon {
        width: 14px;
    }
}

.vc-highlight-bg-light {
    background-color: rgba(233, 233, 238, 0.50) !important;
}

.vc-highlight.vc-highlight-base-end {
    width: 50% !important;
    border-radius: 0 !important;
    border-left-width: 0 !important;
    background-color: #293AD1 !important;
    background: linear-gradient(265deg, #449EF1 -45.55%, #293AD1 156.6%);

}

.vc-highlight.vc-highlight-base-start {
    width: 50% !important;
    border-radius: 0 !important;
    border-right-width: 0 !important;
    background: linear-gradient(265deg, #449EF1 -45.55%, #293AD1 156.6%);
}

.vc-highlight-bg-solid {
    background-color: #293AD1;
    border-radius: 10px !important;
    background: linear-gradient(265deg, #449EF1 -45.55%, #293AD1 156.6%);
}

.vc-day-content {
    font-size: 12px !important;
}

.vc-day-content:hover {
    background-color: none;
}

.vc-weekdays {
    border-bottom: 1px solid #EFEFF4;
}

.vc-weekday {
    color: #040404 !important;
    font-size: 13px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: 128%; /* 16.64px */
    letter-spacing: -0.156px !important;
}

.vc-monthly .is-not-in-month * {
    opacity: 0.6 !important;
    pointer-events: all !important;
}

.vc-popover-content.direction-bottom {
    background: #fff;
    border-radius: 20px;
    border-color: #eee;
}

.vc-nav-item {
    background: transparent;
    font-size: 12px;
}

.vc-nav-arrow {
    border-radius: 11px;
}

.vc-focus:focus-within {
    outline: 0;
    box-shadow: none;
}

@import "node_modules/vue-scroll-datepicker-cashbac/assets/scrolldatepicker.scss";

.preset {
    display: inline-flex;
    align-items: center;
    justify-content: space-around;
    cursor: pointer;

    .select-button {
        font-size: 13px;
        line-height: 20px;
        color: #9A9DAB;
        background: #fff;
        border-radius: 12px;
        padding: 6px 12px;
        flex: 1 1 auto;
        margin-right: 4px;
        white-space: nowrap;
        transition: .3s;
        border: 1px solid #9A9DAB;

        &--active {
            background: #b3b3b3;
            color: #fff;
        }

        &:hover {
            background: rgba(154, 157, 171, 0.1);
            border: 1px solid rgba(154, 157, 171, 0.3);
        }
    }
}

.wheel-range-inputs {
    display: inline-flex;
    margin-top: 10px;
    color: #9A9DAB;

    input {
        height: 34px;
        border-radius: 12px;
        color: #9A9DAB;
        width: 100%;
        text-align: center;
        text-overflow: ellipsis;
        whitespace: nowrap;
        font-size: 13px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;
        border: 1px solid #dcdde0;
        box-sizing: border-box;
        cursor: pointer;

        &.hover-input {
            border-radius: 12px;
            border: 1px solid #9A9DAB;
            background: #fff;
            color: #9A9DAB;
            text-align: center;
            text-overflow: ellipsis;
            whitespace: nowrap;
            font-size: 13px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }
    }
}

.vue-date-select-container {
    position: relative;
    visibility: visible;
    opacity: 1;
    background: none;
    background: transparent;
    border-radius: 0;
}

.vue-date-select-container .vm-dialog-content {
    width: 100%;
    min-width: 280px;
    max-width: 550px;
    border-radius: 8px;
    background: #fff;
    opacity: 1;
    padding: 0;
    overflow: hidden;

    .vm-wheels::before {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgb(227, 227, 227) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#090A0D", endColorstr="#090A0D", GradientType=0);
        height: 82px;
    }

    .vm-wheels::after {
        background: linear-gradient(to top, rgba(255, 255, 255, 0) 0%, rgb(227, 227, 227) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#090A0D", endColorstr="#090A0D", GradientType=0);
        height: 82px;
    }

    .vm-wheels .vm-wheel {
        border-top: 0;
        border-bottom: 0;
        background: none;
        height: 200px;

        &:first-child .vm-line {
            border-radius: 8px 0 0 8px;
            margin-left: 6px;
        }

        &:last-child .vm-line {
            border-radius: 0 8px 8px 0;
            width: calc(100% - 6px);
        }

        .vm-line {
            margin-top: -17px;
            border-top: 0;
            border-bottom: 0;
            height: 34px;
            pointer-events: none;
            background: rgba(97, 179, 255, 0.1);
        }
    }

    .vm-option {
        color: #9A9DAB;
        font-size: 13px;
        font-style: normal;
        font-weight: 300;

        &.active-option {
            background: var(--Blue, linear-gradient(265deg, #61B3FF -45.55%, #4F5EE7 156.6%));
            background-clip: text;
            font-weight: 600;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }
}

</style>