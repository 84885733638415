import {createI18n} from "vue-i18n";

import enCommon from '../locales/en/common.json';
import ruCommon from '../locales/ru/common.json';
import deCommon from '../locales/de/common.json';
import trCommon from '../locales/tr/common.json';
import esCommon from '../locales/es/common.json';
import hiCommon from '../locales/hi/common.json';
import zhHansCommon from '../locales/zh-Hans-CN/common.json';

const savedLanguage = localStorage.getItem("language") || "en";

const messages = {
    en: enCommon,
    ru: ruCommon,
    de: deCommon,
    tr: trCommon,
    es: esCommon,
    hi: hiCommon,
    zh: zhHansCommon
}

export const i18n = createI18n({
    locale: savedLanguage, // set locale
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
})





