<template>
    <div class="more-button" @click="click"><span class="blue-text">{{ $t('table.More') }}</span></div>
</template>

<script>
export default {
    name: "MoreButton",
    methods: {
        click(){

        }
    }
}
</script>

<style scoped>

</style>