<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "ToolModalInner",
    data() {
        return {
            open: false,
            isMoving: false,
            deltaY: 0,
            startY: 0,
            noStyle: true,
            needVibrate: true,
            y_offsetWhenScrollDisabled: 0
        }
    },
    props: {
        openModal: {
            type: Boolean,
            default: false
        },
    },
    mounted() {
        this.bus.on('hide', this.hideModal)
    },
    unmounted() {
        if (this.needVibrate) {
            this.needVibrate = false;
        }
    },
    methods: {
        hideModal() {
            this.open = false;
            document.body.classList.remove("modal-open");
            document.body.setAttribute("style", "margin-top: 0");
            window.scroll({
                top: this.y_offsetWhenScrollDisabled,
                behavior: "instant"
            })
        },
        moveStart(event) {
            if (event.target === this.$refs.drawer
                || event.target === this.$refs.modalInner
                || event.target.classList.contains('drawable')
                || event.target.classList.contains('modal-window__content')
            ) {
                this.isMoving = false;
                this.deltaY = 0;
                this.startY = event.touches[0].clientY;
                this.noStyle = false;
            } else {
                this.isMoving = true;
            }
        },
        movingHandler(event) {
            if (!this.isMoving) {
                const delta = event.touches[0].clientY - this.startY;
                if (delta < 0) {
                    this.deltaY = 0;
                } else {
                    this.deltaY = delta;
                }

                if (this.deltaY >= this.$refs.modalInner.offsetHeight / 4) {
                    if (this.needVibrate) {
                        // this.$vibrate(50);
                    }
                    this.needVibrate = false;
                } else {
                    this.needVibrate = true;
                }
            }
        },
        moveEnd() {
            if (!this.isMoving) {
                this.noStyle = true;

                if (this.deltaY >= this.$refs.modalInner.offsetHeight / 4) {
                    this.hideModal();
                }
                this.deltaY = 0;
                this.startY = 0;
            }
        }
    },
    watch: {
        openModal() {
            this.open = this.openModal;
            if (this.openModal) {
                this.y_offsetWhenScrollDisabled = window.scrollY
                document.body.setAttribute("style", "margin-top: -" + this.y_offsetWhenScrollDisabled + 'px');
                document.body.classList.add("modal-open");
            } else {
                document.body.classList.remove("modal-open");
                document.body.setAttribute("style", "margin-top: 0");
                window.scroll({
                    top: this.y_offsetWhenScrollDisabled,
                    behavior: "instant"
                })
            }

        }
    }
})
</script>

<template>
    <transition name="custom-toolbox">
        <div class="tool-modal" v-if="open">
            <div>
                <transition name="inner-toolbox" :key="index" appear>
                    <div class="tool-modal_inner"
                         :style="noStyle ? '' : 'z-index: 4; transform: translateY('+deltaY+'px);'"
                         ref="modalInner"
                         v-touch:press="moveStart"
                         v-touch:drag="movingHandler"
                         v-touch:release="moveEnd">
                        <slot/>
                        <div class="tool-modal__drawer" ref="drawer"></div>
                    </div>
                </transition>
            </div>
        </div>
    </transition>
</template>

<style scoped>
.custom-toolbox-enter-active,
.custom-toolbox-leave-active {
    transition: opacity .2s ease-in;
}

.custom-toolbox-enter-from, .custom-toolbox-leave-to {
    opacity: 0;
}

.inner-toolbox-enter-active, .inner-toolbox-leave-active {
    transition: transform .2s ease-in;
    z-index: 100;
}

.inner-toolbox-enter-from,
.inner-toolbox-leave-to {
    transform: translateY(100%) !important;
}
</style>