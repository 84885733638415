<template>
    <div class="dropdown" @click="dropDownClick" v-click-outside="onClickOutside">
        <div class="selected-options" :class="isDropdownOpen ? 'active' : ''">
            <span v-if="selectedOptions.length === 0">{{title}}</span>
            <span v-else>{{title}} ({{ selectedOptions.length }})</span>
        </div>
        <div class="dropdown-menu" v-show="isDropdownOpen">
            <div class="dropdown-search input" :class="{'focused': searchFocused}" @click="dropDownClick('input')">
                <svg class="search-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
                     fill="none">
                    <g clip-path="url(#clip0_308_3284)">
                        <path d="M15.4165 15.4167L18.3332 18.3334M17.4998 9.58341C17.4998 5.21116 13.9554 1.66675 9.58317 1.66675C5.21092 1.66675 1.6665 5.21116 1.6665 9.58341C1.6665 13.9557 5.21092 17.5001 9.58317 17.5001C13.9554 17.5001 17.4998 13.9557 17.4998 9.58341Z"
                              stroke="#9A9DAB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_308_3284">
                            <rect width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
                <form class="search-form">
                    <input
                            @focus="searchFocused = true"
                            @blur="searchFocused = false"
                            class="search-input"
                            type="text"
                            :placeholder="$t('message.searchType')"
                            v-model="search">
                </form>
            </div>

            <div class="option" v-for="option in filteredOptions" :key="option.value">
                <input type="checkbox" class="custom-checkbox" :id="option.value" :value="option.value"
                       v-model="option.checked" @change="updateSelectedOptions">
                <label class="label-container" :for="option.value">
                    <div class="option-label">{{ option.label }}</div>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CheckBoxDrop",
        data() {
            return {
                selectedOptions: [],
                isDropdownOpen: false,
                searchFocused: false,
                search: null
            }
        },
        props: {
            options: {
                type: Array,
                default: () => ([])
            },
            title: {
                type: String,
                default: 'Title'
            }
        },
        computed: {
            filteredOptions() {
                let filteredItems = this.options;
                if (this.search) {
                    filteredItems = this.options.filter(item => item.label.toLowerCase().includes(this.search.toLowerCase()));
                }
                return filteredItems
            }
        },
        mounted() {
            this.bus.on('clean', this.clean)
        },
        methods: {
            clean(){
                this.selectedOptions = []
                this.options.forEach((item)=>{
                    item.checked = false
                })
            },
            onClickOutside () {
                this.isDropdownOpen = false;
            },
            dropDownClick() {
                this.isDropdownOpen = !this.isDropdownOpen
            },
            updateSelectedOptions() {
                this.selectedOptions = this.options.filter(option => option.checked);
                this.$emit('setOption', this.selectedOptions)
            }
        },
    }
</script>

<style lang="scss">

</style>