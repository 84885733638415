<template>
    <div class="user-dropdown-menu" v-show="userMenuDropdownOpen">
        <div class="user-dropdown-header" style="text-align: left">
            <div class="address-icon"
                 :style="'background-image: url(' + user.avatar + ')'"></div>
            <div>
                <div class="user-nickname">{{ user.name }}
                    <span v-if="user.premium"
                    style="margin-left: -1px; margin-bottom: -1px; width: 15px; height: 15px;"
                    class="premium-icon"></span></div>
                <div class="user-address">{{ shrinkString(user.address, 15, 6) }}
                    <copy-button style="opacity: 0.5; margin-bottom: -4px" :text="user.address" black></copy-button>
                </div>
            </div>
        </div>
        <div class="user-dropdown-body user-connected-wallet-menu">
            <router-link :to="{ name: 'Address', params: { id: user.address }}">
            <div class="item" @click="closeMobileMenu">
                <div>{{ $t('premium.toProfile') }}</div>
                <img style="width: 15px; height: 15px;" src="@/assets/icons/direction-down 2.png">
            </div>
            </router-link>
            <div class="item" @click="openPromoCodesModal">
                <div>{{ $t('premium.promoCodes') }}</div>
                <img style="width: 15px; height: 15px;" src="@/assets/icons/ticket2.svg">
            </div>
            <div class="item" @click="disconnectWallet">
                <div>{{ $t('premium.disconnectWallet') }}</div>
                <img style="width: 15px; height: 15px;" src="@/assets/icons/flash-off.png">
            </div>
        </div>
        <div class="user-dropdown-footer" style="padding: 6px;">
            <adv-premium :user="user"></adv-premium>
        </div>
    </div>
</template>

<script>

import CopyButton from "@/components/Tools/CopyButton";
import AdvPremium from "@/components/Tools/advPremium";
export default {
    name: "authorizedUserMenu",
    components: { AdvPremium, CopyButton},
    data() {
        return {

        }
    },
    props: {
        user: {
            type: Object,
            default: ()=>{}
        },
        userMenuDropdownOpen: {
            type: Boolean,
            default: false
        }
    },
    methods:{
        openPromoCodesModal(){
            this.bus.emit('openPromoCodesModal', this.user.address)
        },
        disconnectWallet(){
            this.bus.emit('disconnect')
        },
        closeMobileMenu(){
            this.bus.emit('hideMenu')
        }
    }
}
</script>

<style scoped>

</style>