import {createWebHistory, createRouter} from "vue-router";
import Home from "@/components/Blocks/BlocksIndex";
import Transactions from "@/components/Transactions/TransactionsIndex"
import Validators from "@/components/Validators/ValidatorsOverview"

import OneBlock from "@/components/Blocks/OneBlock";
import OneTransaction from "@/components/Transactions/OneTransaction";
import OneValidator from "@/components/Validators/OneValidator";
import OneAddress from "@/components/Accounts/OneAddress";
import StatusProgress from "@/components/Accounts/StatusProgress";

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "/blocks/:id",
        name: "Block",
        component: OneBlock,
        props: true,
    },
    {
        path: "/transactions",
        name: "Transactions",
        component: Transactions,
    },
    {
        path: "/transactions/:id",
        name: "Transaction",
        component: OneTransaction,
        props: true,
    },
    {
        path: "/validators",
        name: "Validators",
        component: Validators,
    },
    {
        path: "/validator/:id",
        name: "Validator",
        component: OneValidator,
        props: true,
    },
    {
        path: "/address/:id",
        name: "Address",
        component: OneAddress,
        props: true,
    },
    {
        path: "/progress/:id",
        name: "Progress",
        component: StatusProgress,
        props: true,
    },
];

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior: function () {
        window.scrollTo(0, 0);
    },
    routes,
});

router.beforeEach(() => {
    document.getElementsByTagName("body")[0].style.position="fixed";
    setTimeout(()=>{
        document.getElementsByTagName("body")[0].style.position="inherit";
        },10)
});

export default router;