<template>
    <div class="copy-icon" :class="[size, black ? 'copy-icon-black' : '',showCopyText? 'success':'']" @click="copy"></div>
</template>

<script>
export default {
    name: "CopyButton",
    props: {
        text: {
            type: String,
            default: ''
        },
        size: {
            type: String,
            default: ''
        },
        black: {
            type: Boolean
        }
    },
    data() {
        return {
            showCopyText: false
        }
    },
    methods: {
        copy() {
            this.showCopyText = true
            setTimeout(() => {
                this.showCopyText = false
            }, 1000)
            this.doCopy(this.text)
        }
    }
}
</script>

<style scoped>
.copy-container {
    /*position: relative;*/
}

.copy-text {
    font-size: 13px;
    /*position: absolute;*/
    opacity: 1;
    font-weight: 100;
    color: black;
    z-index: 999;
    left: -7px;
    top: -22px;
}
</style>