var statusesConditions = {
    data: function () {
        return {
            statusesConditionsObjects: {
                Starter: {
                    RULE_SELF_STAKE: 'Starter-1',
                },
                Leader: {
                    RULE_N_COINS_IN_STRUCTURE: 'Leader-3',
                    RULE_SELF_STAKE: 'Leader-1',
                    RULE_N_REFERRALS_WITH_X_REFERRALS_EACH: 'Leader-2',
                },
                Guru: {
                    RULE_N_COINS_IN_STRUCTURE: 'Guru-3',
                    RULE_SELF_STAKE: 'Guru-1',
                    RULE_N_REFERRALS_WITH_X_REFERRALS_EACH: 'Guru-2',
                },
                Boss: {
                    RULE_N_COINS_IN_STRUCTURE: 'Boss-3',
                    RULE_SELF_STAKE: 'Boss-1',
                    RULE_N_TEAMS_OF_X_PEOPLE_EACH: 'Boss-2',
                },
                Pro: {
                    RULE_N_COINS_IN_STRUCTURE: 'Pro-3',
                    RULE_SELF_STAKE: 'Pro-1',
                    RULE_N_TEAMS_OF_X_PEOPLE_EACH: 'Pro-2',
                },
                Top: {
                    RULE_N_COINS_IN_STRUCTURE: 'Top-3',
                    RULE_SELF_STAKE: 'Top-1',
                    RULE_N_TEAMS_OF_X_PEOPLE_EACH: 'Top-2',
                },
                Mega: {
                    RULE_N_COINS_IN_STRUCTURE: 'Mega-3',
                    RULE_SELF_STAKE: 'Mega-1',
                    RULE_N_TEAMS_OF_X_PEOPLE_EACH: 'Mega-2',
                }
            }
        }
    }
}

export { statusesConditions }