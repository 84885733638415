<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "MenuItems",
    data() {
        return {
            mobileMenuOpen: false,
        }
    },
    props: {

    },
    mounted() {

    },
    unmounted() {

    },
    methods: {
        menuClicked(){
            this.$emit('clicked')
        }
    },
    watch: {

    }
})
</script>

<template>
        <ul class="menu" id="navD">
            <li><router-link to="/"><div class="link block option-label"  @click="menuClicked">{{ $t('menu.Blocks') }}</div></router-link></li>
            <li><router-link to="/transactions"><div class="link transaction option-label"  @click="menuClicked">{{ $t('menu.Transactions') }}</div></router-link></li>
            <li><router-link to="/validators"><div class="link validator option-label"  @click="menuClicked">{{ $t('menu.Validators') }}</div></router-link></li>
        </ul>
</template>

<style scoped>

</style>