var statuses = {
    data: function () {
        return {
            statusesSamples: [
                {
                    id: 'No Status',
                    name: this.$t('status.noStatus'),
                    icon: require ('@/assets/icons/new.png')
                },
                {
                    id: 'Starter',
                    name: this.$t('status.Starter'),
                    icon: require ('@/assets/icons/starter.png')
                },
                {
                    id: 'Leader',
                    name: this.$t('status.Leader'),
                    icon: require ('@/assets/icons/leader.png')
                },
                {
                    id: 'Guru',
                    name: this.$t('status.Guru'),
                    icon: require ('@/assets/icons/guru.png')
                },
                {
                    id: 'Boss',
                    name: this.$t('status.Boss'),
                    icon: require ('@/assets/icons/boss.png')
                },
                {
                    id: 'Pro',
                    name: this.$t('status.Pro'),
                    icon: require ('@/assets/icons/pro.png')
                },
                {
                    id: 'Top',
                    name: this.$t('status.Top'),
                    icon: require ('@/assets/icons/top.png')
                },
                {
                    id: 'Mega',
                    name: this.$t('status.Mega'),
                    icon: require ('@/assets/icons/mega.png')
                },
            ]
        }
    },

}

export { statuses }