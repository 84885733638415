import { createStore } from 'vuex'

export default createStore({
    state: {
        authorizedUser:{},
        otherDomain: false,
    },
    mutations: {
        UPDATE_AUTHORIZED(state, payload) {
            state.authorizedUser = payload
        },
        UPDATE_DOMAIN(state, payload) {
            state.otherDomain = payload
        },
    },
    actions: {
        setAuthorized(context, payload) {
            context.commit('UPDATE_AUTHORIZED', payload)
        },
        setDomainCheck(context) {
            let otherDomain = false
            if (window.location.hostname === 'axiomechain.pro' ||
                window.location.hostname === 'localhost' ||
                window.location.hostname === 'www.axiomechain.pro') {
                otherDomain = true
            }
            context.commit('UPDATE_DOMAIN', otherDomain)
        },
    },
    getters: {
        authorizedUser: function (state) {
            return state.authorizedUser
        },
        otherDomain: function (state) {
            return state.otherDomain
        }
    }
})