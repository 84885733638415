<template>
    <div class="table-lines-filter">
        <div>{{ $t('message.showLines') }} </div>
        <radio-drop :options="lines" @setOption="set" title="10"></radio-drop>
    </div>
</template>

<script>
    import RadioDrop from "@/components/Filters/RadioDrop";
    export default {
        name: "ShowLinesCount",
        components: {RadioDrop},
        data() {
            return {
                lines: [
                    {label: '10', value: '10'},
                    {label: '50', value: '50'},
                    {label: '100', value: '100'},
                ],
            }
        },
        methods:{
            set(e){
                this.$emit('count', e)
            }
        }
    }
</script>

<style scoped>

</style>