<script>
import {defineComponent} from 'vue'
import {i18n} from "../../../plugins/i18n";

export default defineComponent({
    name: "LangSelector",
    data() {
        return {
            languageDropdown: false,
            // selectedLanguage: localStorage.getItem("language") || navigator.language,
            selectedLanguage:  'en',
        }
    },
    props: {

    },
    mounted() {
        this.setLang()
    },
    unmounted() {},
    methods: {

        async setLang() {
            let lang = 'en';

            try {

                const selectedLang = localStorage.getItem('language');

                if (!selectedLang) {

                    let location = await fetch("https://ipinfo.io/json");
                    location = await location.json();

                    if (['RU', 'BY', 'KZ', 'TJ', 'UZ', 'KG', 'AM', 'AZ', 'GE', 'UA'].includes(location.country)) {
                        lang = 'ru';
                    } else if (location.country === 'AT' || location.country === 'DE' || location.country === 'CH') {
                        lang = 'de';
                    } else {
                        lang = 'en';
                    }

                } else {
                    lang = selectedLang;
                }

            } catch (e) {
                console.log(e)
            }

            if (!(['en', 'ru', 'de', 'tr', 'es', 'hi', 'zh'].includes(lang.toLowerCase()))) {
                lang = 'en';
            }

            this.selectedLanguage = lang.toLowerCase();
            i18n.global.locale = lang.toLowerCase();
            localStorage.setItem("language", lang.toLowerCase());
        },
        onClickOutsideLang () {
            this.languageDropdown = false;
        },
    },
    watch: {
        selectedLanguage: function (newLanguage) {
            let lang = newLanguage
            newLanguage?.toLowerCase() === 'ru-RU' || newLanguage?.toLowerCase() === 'ru' ? lang = 'ru': ''
            if(newLanguage === 'DE'){
                lang = 'de';
            }
            localStorage.setItem("language", lang.toLowerCase());
            i18n.global.locale = lang.toLowerCase();
        },
    }
})
</script>

<template>
    <div class="dropdown" @click="languageDropdown = !languageDropdown" v-click-outside="onClickOutsideLang">
        <div class="selected-options">
            <span class="text">{{ selectedLanguage.toUpperCase() }}</span>
        </div>
        <div class="dropdown-menu lang-menu" v-show="languageDropdown">
            <div class="option" @click="selectedLanguage = 'RU'">
                <div class="language-options__item">RU</div>
            </div>
            <div class="option" @click="selectedLanguage = 'EN'">
                <div class="language-options__item">EN</div>
            </div>
            <div class="option" @click="selectedLanguage = 'DE'">
                <div class="language-options__item">DE</div>
            </div>
            <div class="option" @click="selectedLanguage = 'TR'">
                <div class="language-options__item">TR</div>
            </div>
<!--            <div class="option" @click="selectedLanguage = 'ES'">-->
<!--                <div class="language-options__item">ES</div>-->
<!--            </div>-->
<!--            <div class="option" @click="selectedLanguage = 'HI'">-->
<!--                <div class="language-options__item">HI</div>-->
<!--            </div>-->
            <div class="option" @click="selectedLanguage = 'ZH'">
                <div class="language-options__item">ZH</div>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>