<template>
    <div class="countdown-container">
        <div class="countdown-left-part">
            <img class="advantages-item-icon"
                 style="width: 36px; height: 36px;" src="@/assets/icons/hourglass-outline-hero.svg">
            <div style="padding-right: 10px; padding-left: 10px" class="grey-border-right pressed">
                {{ $t('kpi.timeToResults') }}
            </div>
        </div>
        <div class="countdown-right-part">
            <countdown v-if="timestamp" :time="timestamp-Date.now()" format="DD ~Day, HH:mm:ss">
                <template #default="{ resolved }">
                    <div class="pressed" style="display: flex">
                        <div class="text-center">
                            <div class="text-3xl text-umio-gradient font-normal">
                                {{ resolved.DD }}</div>
                            <div class="text-xs px-2 text-umio-dark-grey"> {{
                                    $t('days')
                                }}
                            </div>
                        </div>
                        <span class="text-umio-gradient pr-4 pl-4 dot-between">:</span>
                        <div class="text-center">
                            <div class="text-3xl text-umio-gradient font-normal">{{ resolved.HH }}</div>
                            <div class="text-xs px-2 text-umio-dark-grey">{{
                                    $t('hours')
                                }}
                            </div>
                        </div>
                        <span class="text-umio-gradient pr-4 pl-4 dot-between">:</span>
                        <div class="text-center">
                            <div class="text-3xl text-umio-gradient font-normal">{{ resolved.mm }}</div>
                            <div class="text-xs px-2 text-umio-dark-grey">{{
                                    $t('mins')
                                }}
                            </div>
                        </div>
                        <span class="text-umio-gradient pr-4 pl-4 dot-between">:</span>
                        <div class="text-center">
                            <div class="text-3xl text-umio-gradient font-normal">{{ resolved.ss }}</div>
                            <div class="text-xs px-2 text-umio-dark-grey">{{
                                    $t('seconds')
                                }}
                            </div>
                        </div>
                    </div>
                </template>
            </countdown>
        </div>
    </div>
</template>

<script>
import Countdown from "vue3-countdown";

export default {
    name: "CountdownBlock",
    mixins: [],
    components: {Countdown},
    data() {
        return {}
    },
    props: {
        timestamp: {
            type: Number,
            default: 0
        },
    },
    created() {

    },
    mounted() {
    },
    beforeUnmount() {
    },

    methods: {}
}
</script>

<style scoped>
.countdown-container{
    display: inline-flex;
    flex-direction: row;
    width: 100%;
    font-family: Inter Tight thin;
    font-weight: 100;
}

@media (max-width: 767px) {
    .countdown-container {
        display: block;
    }
}
.countdown-left-part{
    max-width: 250px;
    width: fit-content;
    margin-bottom: .25rem;
    border-color: #c3bcd066;
    align-items: center;
    display: flex;
    line-height: 20px;
}
@media (max-width: 767px) {
    .countdown-left-part {
        max-width: 100%;
        font-size: 13px;
        margin-bottom: 10px;
    }
}
.countdown-right-part{
    justify-content: center;
    align-items: center;
    display: flex;
    flex-grow: 1;
}
@media (max-width: 767px) {
    .countdown-right-part {
        border-top:1px solid #c3bcd066;
        padding-top: 10px;
        max-width: 100%;
        font-size: 14px;
    }
}
.text-3xl{
    font-size: 34px;
    line-height: 40px;
}
@media (max-width: 767px) {
    .text-3xl {
        font-size: 26px;
        line-height: 30px;
    }
}

.text-xs {
    font-size: 12px;
    line-height: 16px;
}
.px-2 {
    padding-left: .5rem;
    padding-right: .5rem;
}
.pl-4 {
    padding-left: 1rem;
}
.pr-4 {
    padding-right: 1rem;
}
.dot-between{
    margin-top: 7px;
    padding-left: 24px;
    padding-right: 24px;
}
@media (max-width: 767px) {
    .dot-between {
        padding-left: 14px;
        padding-right: 14px;
    }
}


.text-umio-dark-grey{
    color: rgb(162 155 174);
}
@media (min-width: 768px) {
    .grey-border-right {
        border-right: 1px solid #C3BCD066;
    }
}
</style>