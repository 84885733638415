<template>
    <div class="pair-buttons">
        <div class="button" :class="active == 1? 'active' : ''" @click="clickOne">{{button1}} <span v-if="count1">({{count1}})</span></div>
        <div class="button" :class="active == 2? 'active' : ''" @click="clickTwo">{{button2}} <span v-if="count1">({{count2}})</span></div>
    </div>
</template>

<script>
    export default {
        name: "PairButtons",
        data() {
            return {
                active: 1
            }
        },
        props: {
            button1: {
                type: String,
                default: ''
            },
            count1: {
                type: Number,
                default: null
            },
            button2: {
                type: String,
                default: ''
            },
            count2: {
                type: Number,
                default: null
            },
        },
        methods:{
            clickOne(){
                this.active = 1
                this.$emit('clickOne')
            },
            clickTwo(){
                this.active = 2
                this.$emit('clickTwo')
            }
        }
    }
</script>

<style scoped>

</style>