<template>
    <div class="dropdown-search global-search input" :class="{'focused': searchFocused}">
        <svg @click="submitSearch" class="search-icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"
             fill="none">
            <g clip-path="url(#clip0_308_3284)">
                <path
                    d="M15.4165 15.4167L18.3332 18.3334M17.4998 9.58341C17.4998 5.21116 13.9554 1.66675 9.58317 1.66675C5.21092 1.66675 1.6665 5.21116 1.6665 9.58341C1.6665 13.9557 5.21092 17.5001 9.58317 17.5001C13.9554 17.5001 17.4998 13.9557 17.4998 9.58341Z"
                    stroke="#9A9DAB" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_308_3284">
                    <rect width="20" height="20" fill="white"/>
                </clipPath>
            </defs>
        </svg>
        <form class="search-form" @submit.prevent="submitSearch">
            <input
                @focus="searchFocused = true"
                @blur="searchFocused = false"
                class="search-input"
                type="text"
                :placeholder="$t('searchPlaceholder.'+ placeholder)"
                v-model="search">
        </form>
        <div class="clean-search" @click="clean" v-if="search">
            <svg width="9" height="9" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.7989 1.08123C6.04298 0.837147 6.04298 0.441419 5.7989 0.197342C5.55483 -0.0467359 5.1591 -0.0467359 4.91502 0.197342L2.99978 2.11258L1.08476 0.197557C0.840686 -0.0465211 0.444958 -0.0465206 0.20088 0.197557C-0.0431973 0.441635 -0.0431976 0.837362 0.20088 1.08144L2.1159 2.99646L0.200975 4.91139C-0.0431031 5.15547 -0.0431026 5.55119 0.200975 5.79527C0.445053 6.03935 0.84078 6.03935 1.08486 5.79527L2.99978 3.88034L4.91493 5.79549C5.159 6.03956 5.55473 6.03956 5.79881 5.79549C6.04289 5.55141 6.04289 5.15568 5.79881 4.9116L3.88367 2.99646L5.7989 1.08123Z" fill="#9A9DAB"/>
            </svg>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import {SEARCH} from "../../../routes/routes"
import {useToast} from "vue-toastification";

const toast = useToast();

export default {
    name: "GlobalSearch",
    props: {
        placeholder: {
            type: String,
            default: 'default'
        }
    },
    data() {
        return {
            search: '',
            searchFocused: false,
        }
    },

    mounted() {
        this.bus.on('clean', this.clean)
    },
    methods: {
        clean() {
            this.search = ''
        },
        submitSearch() {
            if (this.search) {
                let trueFormat = false
                if (this.search.trim().toLowerCase()?.includes('axm1') || this.search.trim().toLowerCase()?.includes('axmvaloper1')) {
                    trueFormat = true
                }
                if (/^[0-9]+$/i.test(this.search.trim())) {
                    trueFormat = true
                }
                if (/^[A-Za-z0-9-_]+$/i.test(this.search.trim())) {
                    trueFormat = true
                }

                if (!trueFormat) {
                    toast(this.$t('toasts.wrongPromptFormat'), { timeout: 2000, toastClassName: "grey-toast", });
                    return
                }

                axios({
                    method: "post",
                    url: SEARCH,
                    data: {query: this.search.trim()},
                    timeout: 1000 * 20,
                }).then(({data}) => {

                    if (data.type == 'unknown') {
                        toast.info(this.$t('toasts.nothingFound'), { timeout: 2000, toastClassName: "grey-toast", });
                        return data;
                    }

                    if (data.data == null) {
                        toast.info(this.$t('toasts.nothingFound'), { timeout: 2000, toastClassName: "grey-toast", });
                        return data;
                    }

                    //3D2E012BA00C78E6522861B8EFA3000B2998CA14DA79A5A0BB439BF1A54AF8B7
                    if (data.type == 'block') {
                        this.$router.push({
                            name: 'Block',
                            params: {
                                id: data?.data?._id,
                            }
                        })
                    }

                    //7472F35C7CE07A286389E8B80E127BCB87C8C7061B6437F9401F7C44FB28DE18
                    if (data.type == 'tx') {
                        this.$router.push({
                            name: 'Transaction',
                            params: {
                                id: data?.data?._id,
                            }
                        })
                    }

                    //axm1k9aufp337q777up20a6lqdatym7myfwedctgk2
                    if (data.type == 'account') {
                        this.$router.push({
                            name: 'Address',
                            params: {
                                id: data?.data?.account?.address,
                            }
                        })
                    }

                    if (data.type == 'validator') {
                        this.$router.push({
                            name: 'Validator',
                            params: {
                                id: data?.data?.address,
                            }
                        })
                    }

                    this.$emit('searchSubmit');
                }).catch(e => {
                    toast.info(this.$t('toasts.error') + ' ' + e);
                })
            }
        }
    },
    watch: {
        search() {
            this.$emit('search', this.search)
        }
    }
}
</script>

<style scoped>

.icon {
    width: 20px;
    height: 20px;
    margin: 0 0 0 10px;
    background: url('../../assets/icons/search.png') center/cover no-repeat;
}


::-webkit-input-placeholder {
    text-align: left;
}

::-webkit-input-placeholder::before {
    content: '123';
    padding-right: .2em;
}

:-moz-placeholder { /* Firefox 18- */
    text-align: center;
}

:-moz-placeholder::before {
    content: '123';
    padding-right: .2em;
}

</style>