<template>
    <div>
        <div class="page-title core-page-title">
            {{ $t('message.transactions') }}
        </div>
        <div class="info-table-block">
            <div class="filters-container">
                <div class="filters">
                    <check-box-drop @setOption="setFilterMessages" :options="optionsFilter1"
                                    :title="$t('table.MessageType')"></check-box-drop>
                    <radio-drop @setOption="setFilterStatus" :options="optionsFilter2" :title="$t('table.TransactionsStatus')"
                                class="side-dropdown"></radio-drop>
                    <calendar-drop @rangeSetted="setFilterRange" :range="{'start':from, 'end': to, min: '2023-10-02'}"
                                   :title="$t('table.TimeRange')"></calendar-drop>
                    <clear-button @click="cleanFilters"></clear-button>
                </div>
            </div>

            <div class="row row-overflow-y min-width-100">
                <div class="info-table">
                    <transactions-table :transactions="transactions.data"></transactions-table>
                </div>
            </div>
            <div class="table-nav">
                <div class="show-all grey-button button" style="visibility: hidden; display: none">
                    Показать все
                </div>
                <explorer-paginator :current-page="transactions.current_page" :last-page="transactions.last_page"
                                    @pagePlusArgument="pagePlusArgument" @prevPage="prevPage"
                                    @nextPage="nextPage" @goLastPage="goLastPage"/>
                <show-lines-count @count="setPageCount"></show-lines-count>
            </div>
        </div>
    </div>
</template>

<script>
import {TRANSACTIONS_LIST, TRANSACTIONS_COUNT, TX_FILTER} from "../../../routes/routes"
import ExplorerPaginator from "@/components/Tools/ExplorerPaginator";
import TransactionsTable from "@/components/Transactions/TransactionsTable";
import CheckBoxDrop from "@/components/Filters/CheckBoxDrop";
import RadioDrop from "@/components/Filters/RadioDrop";
import CalendarDrop from "@/components/Filters/CalendarDrop";
import ShowLinesCount from "@/components/Filters/ShowLinesCount";
import axios from "axios";
import ClearButton from "@/components/Tools/ClearButton";

export default {
    name: "TransactionsIndex",
    components: {
        ClearButton,
        ShowLinesCount, CalendarDrop, RadioDrop, TransactionsTable, ExplorerPaginator, CheckBoxDrop},
    data() {
        return {
            from: null,
            to: null,
            pageCount: 10,
            countReloadNeed: true,

            filterStatus: '',
            filterMessages: '',
            filterTime: '',

            transactions: {
                current_page: 1,
                data: [],
                last_page: 0
            },
            optionsFilter1: [],
            optionsFilter2: [
                {label: this.$t('table.All'), value: 3},
                {label: this.$t('table.Success'), value: 1},
                {label: this.$t('table.Fail'), value: 2},
            ],
        }
    },
    created() {
        this.fetchTransactions();
        this.fetchMessagesFilterTypes();
    },
    computed: {},
    mounted() {
    },
    methods: {
        fetchTransactions() {
            let params = ''
            params = this.filterMessages || this.filterStatus || this.filterTime ? '?' : ''
            params = this.filterMessages ? params + 'type=' + this.filterMessages : params
            params = this.filterStatus ? params + '&status=' + this.filterStatus : params;
            params = this.filterTime ? params + '&time=' + this.filterTime : params;

            axios({
                method: "get",
                url: TRANSACTIONS_LIST + this.pageCount + '/'
                    + this.transactions.current_page
                    + params,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.transactions.data = []
                info.data?.forEach((transaction) => {
                    let trans = {
                        messages: transaction.type,
                        hash: transaction._id,
                        height: transaction.blockId,
                        status: transaction.code,
                        txTime: this.$dayjs(transaction.timestamp).unix(),
                    }
                    this.transactions.data.push(trans)
                })

                this.transactions.current_page = info.page
                if (this.countReloadNeed) {
                    this.getTransactionsCount()
                    this.countReloadNeed = false;
                }
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        getTransactionsCount() {
            let params = ''
            params = this.filterMessages || this.filterStatus || this.filterTime ? '?' : ''
            params = this.filterMessages ? params + 'type=' + this.filterMessages : params
            params = this.filterStatus ? params + '&status=' + this.filterStatus : params;
            params = this.filterTime ? params + '&time=' + this.filterTime : params;

            axios({
                method: "get",
                url: TRANSACTIONS_COUNT + params,
                timeout: 1000 * 20,
            }).then(response => {
                let info = response.data
                this.transactions.last_page = Math.ceil(info.totalRecords / this.pageCount)
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        fetchMessagesFilterTypes(){
            axios({
                method: "get",
                url: TX_FILTER,
                timeout: 1000 * 20,
            }).then(response => {
                let list = response.data
                if(list.length){
                    this.optionsFilter1=[]
                    for (const element of list) {
                        let array = element.split('.')
                        let last = array[array.length-1]
                        this.optionsFilter1.push(
                            {
                                label: last ? last : element,
                                value: element,
                                checked: false
                            },
                        )
                    }
                }
            }).catch(error => {
                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        setPageCount(e) {
            this.pageCount = e
            this.transactions.current_page = 1
            this.countReloadNeed = true;
            this.fetchTransactions()
        },
        setFilterStatus(e) {
            this.filterStatus = (e - 1).toString();
            this.transactions.current_page = 1
            this.countReloadNeed = true;
            this.fetchTransactions()
        },
        setFilterRange(e) {
            this.filterTime = e;
            this.transactions.current_page = 1
            this.countReloadNeed = true;
            this.fetchTransactions()
        },
        setFilterMessages(e) {
            this.filterMessages = ''
            let arr = []
            if (e.length) {
                e.forEach((mess) => arr.push(mess.value))
            }
            this.filterMessages = arr.toString()
            this.transactions.current_page = 1
            this.countReloadNeed = true;
            this.fetchTransactions()
        },
        cleanFilters() {
            this.bus.emit('clean')
            this.filterStatus = ''
            this.filterMessages = ''
            this.filterTime = ''
            this.countReloadNeed = true;
            this.fetchTransactions()
        },
        prevPage() {
            if (this.transactions.current_page > 1) {
                this.transactions.current_page = this.transactions.current_page - 1
                this.fetchTransactions()
            }
        },
        nextPage() {
            if (this.transactions.current_page < this.transactions.last_page) {
                this.transactions.current_page = this.transactions.current_page + 1
                this.fetchTransactions()
            }
        },
        goLastPage() {
            this.transactions.current_page = this.transactions.last_page
            this.fetchTransactions()
        },
        pagePlusArgument(e) {
            this.transactions.current_page = this.transactions.current_page + e
            this.fetchTransactions()
        },
    }
}
</script>

<style scoped>

</style>