<template>
    <router-link :to="{ name: 'Transaction', params: { id: hash }}">{{ hashShort }}</router-link>
</template>

<script>
export default {
    name: "TransactionHash",
    props: {
        hash: {
            type: String
        }
    },

    computed: {
        hashShort() {
            return this.hash.substring(0, 16) + '...' + this.hash.substring(this.hash.length - 16)
        }
    }
}
</script>

<style scoped>

</style>