<template>
    <div class="paginator">
        <div class="prev paginator-button" :class="{active: (prevAndNextOnly || currentPage > 1)}"
             @click.prevent="goToPage('prev')"/>

        <div class="go-to-block" v-if="!prevAndNextOnly">
            <div class="go-to prev-first" v-if="itIsVisible('prev-first')"
                 @click.prevent="goToPage('prev')"
            >
                {{ currentPage - 1 }}
            </div>
            <div class="go-to first" :class="{'onlyOne': lastPage === currentPage}">
                {{ currentPage }}
            </div>
            <div class="go-to" @click.prevent="goToPage('next')"
                 v-if="itIsVisible('plusOnePage')"
            >
                {{ currentPage + 1 }}
            </div>
            <div class="go-to" v-if="itIsVisible('plusTwoPage')"
                 @click.prevent="goToPage(2)"
            >
                {{ currentPage + 2 }}
            </div>
            <div class="go-to" v-if="itIsVisible('plusThreePage')"
                 @click.prevent="goToPage(3)"
            >
                {{ currentPage + 3 }}
            </div>
            <div class="go-to" v-if="itIsVisible('plusFourPage')"
                 @click.prevent="goToPage(4)"
            >
                {{ currentPage + 4 }}
            </div>
            <div class="go-to more" v-if="itIsVisible('more')">
                {{ '...' }}
            </div>
            <div class="go-to last" @click.prevent="goToPage('last')"
                 v-if="itIsVisible('lastPage')">
                {{ lastPage }}
            </div>
        </div>

        <div class="next paginator-button" :class="{active: (prevAndNextOnly || currentPage < lastPage)}"
             @click.prevent="goToPage('next')"
        />
    </div>
</template>

<script>

    export default {
        name: "ExplorerPaginator",
        props: {
            prevAndNextOnly: {
                type: Boolean,
                default: false,
            },
            currentPage: {
                type: Number,
                default: 1,
            },
            lastPage: {
                type: Number,
                default: 1,
            }
        },
        data() {
            return {
                window: {
                    width: 0,
                    height: 0
                }
            }
        },
        computed: {
        },
        created() {
            window.addEventListener('resize', this.handleResize);
            this.handleResize();
        },
        unmounted() {
            window.removeEventListener('resize', this.handleResize);
        },
        methods: {
            itIsVisible(element){
                if(!element){
                    return
                }

                if(element === 'prev-first'){
                    return this.currentPage - 1 > 0;
                }

                if(element === 'plusOnePage'){
                    return this.currentPage + 1 < this.lastPage;
                }

                if(element === 'plusTwoPage'){
                    return this.currentPage + 2 < this.lastPage && this.window.width > 321;
                }

                if(element === 'plusThreePage'){
                    return this.currentPage + 3 < this.lastPage && this.window.width > 321;
                }

                if(element === 'plusFourPage'){
                    return this.currentPage + 4 < this.lastPage && this.window.width > 321;
                }

                if(element === 'more'){
                    return this.lastPage - 5 > this.currentPage && this.window.width > 321;
                }

                if(element === 'lastPage'){
                    return this.currentPage < this.lastPage;
                }

                return null;
            },
            goToPage(page){
                if (Number(page)){
                    this.$emit('pagePlusArgument', page)
                    return
                }

                page === 'prev' ? this.$emit('prevPage') : null;
                page === 'next' ? this.$emit('nextPage') : null;
                page === 'last' ? this.$emit('goLastPage') : null;
            },
            handleResize() {
                this.window.width = window.innerWidth;
                this.window.height = window.innerHeight;
            },
        },
        watch: {
        }
    }
</script>

<style scoped lang="scss">

    .paginator {
        margin-top: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 13px;
        margin-left: 8px;
        margin-bottom: 12px;

        .paginator-button {
            display: flex;
            align-items: center;
            justify-content: center;

            height: 32px;
            width: 32px;

            opacity: .3;

            &.active {
                cursor: pointer;
                transition: all .1s linear;

                &:hover{
                    opacity: 1;
                    transform: scale(1.05);
                }

                @media (max-width: 500px) {
                    &:hover{
                        opacity: .3;
                        transform: inherit;
                    }

                    &:active{
                        opacity: 1;
                        transform: scale(1.05);
                    }
                }
            }

            &.next {
                margin: 0 0 0 10px;
                border-radius: 12px;
                opacity: 1;
                position: relative;
                width: 30px;
                height: 47px;
                @media (min-width: 767px) {
                    width: 46px;
                    background: var(--Stroke, rgba(63, 66, 80, 0.04));
                }

                &::before {
                    content: "";
                    display: block;
                    background: url("@/assets/icons/direction-down 2.png") bottom/80% no-repeat;
                    width: 24px;
                    height: 24px;
                    transform: rotate(0deg);
                    flex-shrink: 0;
                    position: absolute;
                    left: 0px;
                    top: 9px;
                    @media (min-width: 767px) {
                        left: 10px;
                    }
                }
            }

            &.prev {
                margin: 0 10px 0 0;
                transform: rotate(0deg);
                border-radius: 12px;
                opacity: 1;
                width: 30px;
                height: 47px;
                @media (min-width: 767px) {
                    width: 46px;
                    background: var(--Stroke, rgba(63, 66, 80, 0.04));
                }

                &::before {
                    content: "";
                    display: block;
                    background: url("@/assets/icons/direction-down 2.png") bottom/80% no-repeat;
                    width: 24px;
                    height: 24px;
                    transform: rotate(180deg);
                    flex-shrink: 0;
                    position: absolute;
                    top:14px;
                    right: 0px;

                    @media (min-width: 767px) {
                        right: 12px;
                    }
                }

                @media (max-width: 350px){

                }

                &:hover{

                    &.active{
                        transform: scale(1.05);
                    }
                }


            }

            @media (max-width: 350px){
                height: 28px;
                width: 28px;
                line-height: 14px;
            }
        }

        .go-to-block{
            display: flex;
            margin: 0;

            .go-to{
                display: flex;
                align-items: center;
                justify-content: center;
                color: var(--Dark-Grey, #3F4251);
                font-style: normal;
                font-weight: 600;
                line-height: 128%; /* 16.64px */
                letter-spacing: -0.156px;
                width: auto;
                border-radius: 12px;
                padding: 7px 13px;
                cursor: pointer;
                transition: all .1s linear;

                &:hover{
                    transform: scale(1.05);
                }

                &.first{
                    color: #fff;
                    cursor: default;
                    background: $dark-grey-linear;

                    &:hover{
                        transform: none;
                    }
                }

                &.more{
                    cursor: default;
                    padding: 0;

                    &:hover{
                        opacity: 0.3;
                        transform: none;
                    }
                }

                &.last{
                    margin: 0;
                }

                &.onlyOne{
                    margin: 0 !important;
                }

                @media (max-width: 767px) {
                    &:active{
                        opacity: 1;
                        transform: scale(1.05);
                    }
                }

                @media (max-width: 350px){
                    height: 28px;
                    min-width: 28px;
                    line-height: 14px;
                }
            }
        }
    }
    @media (min-width: 767px) {
        .paginator {
            font-size: 16px;
            justify-content: left ;

            .go-to-block {
                .go-to {
                    margin-right: 8px;
                    background: var(--Stroke, rgba(63, 66, 80, 0.04));
                    padding: 13px 20px;

                    &.more{
                        background: transparent;
                    }
                }
            }
        }
    }

</style>
