<template>
    <button class="sort-button">
        <slot/>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"
             style="margin-left: 2px;">
            <path d="M3.33594 6L5.33594 4M5.33594 4L7.33594 6M5.33594 4V12"
                  :stroke="sortDirection === 1 ? '#000000' : '#9A9DAB'" stroke-linecap="round"
                  stroke-linejoin="round"/>
            <path d="M12.6641 10L10.6641 12M10.6641 12L8.66406 10M10.6641 12L10.6641 4"
                  :stroke="sortDirection === -1 ? '#000000' : '#9A9DAB'"
                  stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    </button>
</template>
<script>
export default {
    name: "TableFilter",

    props: {
        isOrdered: {
            type: Boolean
        },

        direction: {
            type: Number
        }
    },

    computed: {
        sortDirection() {
            return this.isOrdered ? this.direction : 0;
        }
    }
}
</script>

<style scoped>

</style>