<template>
    <div class="premium-re">
        <div class="re-bg"></div>
        <div v-if="!user?.premium">
            <span class="main-re">{{ $t('premium.advTitle') }}</span>
            <div class="get-re" @click="emitGetPremium">{{ $t('premium.getPremium') }}</div>
        </div>
        <div v-else>
            <span class="main-re">{{ $t('premium.advTitleExtend') }}
                <span v-if="user.premiumExp">{{timeCookingUTCDateOnly((Date.parse(user.premiumExp))/1000)}}</span>
            </span>
            <div class="get-re" @click="emitGetPremium">{{ $t('premium.ExtendPremium') }}</div>
        </div>

    </div>
</template>

<script>
export default {
name: "advPremium",
    props: {
        user: {
            type: Object,
            default: ()=>{}
        },
    },
    methods:{
        emitGetPremium(){
            this.bus.emit('getPremium')
        }
    }
}
</script>

<style scoped>

</style>