<template>
    <Popup key="promoCodesPopup" :openModal="openModal" @hidePopup="hidePopup" class="kpiPopup">
        <div class="status-detail">
            <div class="status-detail_body">
                <div class="popup-title">{{ $t('promoCodes.promoCodesModalTitle') }}</div>
                <div class="first-block">
                    {{ $t('promoCodes.promoCodesModalTitleDescription') }}
                </div>
                <div class="status-detail_body_conditions" v-if="codes && codes.length">
                    <template v-for="(item,index) in codes" :key="index">
                        <div class="item" style="padding: 10px 16px;" @click.stop>
                            <div class="promo-code">{{item._id}}
                                <copy-button class="copy-icon-big" :text="item._id" black></copy-button>
                            </div>
                            <div class="item-subtext grey" style="text-align: left;">
                                <div v-if="item.description" style="color: #a29bae;" v-html="item.description"/>
                                <div v-if="item.activeUntil">{{ $t('promoCodes.promoCodesActivityExpires') }} {{timeCookingUTC(Date.parse(new Date(item.activeUntil)) / 1000)}} </div>
                                <div v-if="item.maxActivations">{{ $t('promoCodes.promoCodesUsed') }}: {{item.activations}}/{{item.maxActivations}} </div>
                            </div>
                        </div>
                    </template>
                </div>
                <div v-else class="status-detail_body_conditions" style="text-align: center; margin-top: 20px">
                    {{ $t('promoCodes.youHaveNoPromoCodes') }}
                </div>
            </div>
        </div>
    </Popup>
</template>

<script>
import Popup from "@/components/Tools/Popup";
import CopyButton from "@/components/Tools/CopyButton";
import axios from "axios";
import {GETPROMOCODES} from "../../../routes/routes";
import {mapGetters} from "vuex";

export default {
    name: "promoCodesPopup",
    components: {CopyButton, Popup},
    data() {
        return {
            openModal: false,
            currentAddress: '',
            codes: [],
        }
    },

    props: {},
    computed: {
        selectedLanguage() {
            return localStorage.getItem("language") || 'en'
        },
        ...mapGetters(['authorizedUser'])
    },

    created() {
        this.bus.on('openPromoCodesModal', (address) => {
            this.openModal = true
            this.fetchPromoCodes(address)
        })
    },

    methods: {
        fetchPromoCodes(address){
            let locale = this.selectedLanguage
            axios({
                method: "get",
                url: GETPROMOCODES + address + '/info' + '?lang=' + locale,
                timeout: 1000 * 20,
            }).then(response => {
                this.codes = response.data
            }).catch(error => {
                if (error.code === 'ERR_BAD_RESPONSE') {
                    this.transaction = null;
                }

                if (error.code === 'ECONNABORTED') {
                    console.log('Request timed out');
                } else {
                    console.log(error.message);
                }
            });
        },
        hidePopup() {
            this.openModal = false
        },
    }
}
</script>

<style lang="scss">
.popup-title {
    font-size: 24px;
    line-height: 25px;
    font-family: Inter Tight;
    text-align: left;
    margin-left: 10px;
}


.first-block {
    color: rgb(162, 155, 174);
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    margin: 5px 10px 10px 10px;
    max-width: 95%;
    font-family: Inter Tight thin;
}

.status-detail {
    height: 100%;
    overflow-y: auto;
}

.status-detail_body .item-subtext {
    font-size: 12px;
    font-family: Inter Tight thin;
    color: #0e0e0e;
}

.statuses {
    margin-top: 20px;
}

.statusRow {
    display: inline-flex;
    align-items: center;
    width: 96%;
    margin-left: 13px;
    margin-bottom: 10px;
    box-sizing: border-box;
}

.status-icon {
    margin-right: 5px;
    width: 36px;
    height: 36px;
    background-size: contain;
    background-position: center;
    border-radius: 12px;
    flex-shrink: 0;
}

.progress {
    font-size: 13px;

    .positive-text {
        font-size: 13px;
    }
}

.progress-number {
    padding-bottom: 2px;
    font-weight: 100;
}

.open-kpi-button {
    margin-left: 0;
    margin-bottom: 20px;
    font-size: 15px;
    width: fit-content;
    margin-top: 24px;
    padding: 13px 20px;
    border-radius: 16px;
}

@media (max-width: 767px) {
    .open-kpi-button {
        box-sizing: border-box;
        width: 100%;
    }
}

.open-kpi-button:hover {
    transform: scale(1.05);
    transition: .3s;
}

.hidden {
    width: 0 !important;
    display: none !important;
}

.kpiPopup .tool-popup_inner {
    max-height: 80vh !important;
}

.promo-code{
    border: 1px solid #EFA535;
    padding: 10px;
    border-radius: 16px;
    font-size: 20px;
    margin-bottom: 10px;
}

.copy-icon-big{
    height: 16px !important;
    width: 18px !important;
    margin-bottom: -1px;
}
</style>