<template>
    <div class="dropdown" ref="filter" :class="distanceFiltersToBottom<230 ?'open-to-top':''" @click="dropDownClick" v-click-outside="onClickOutside">
        <div class="selected-options" :class="isDropdownOpen ? 'active' : ''">
            <span v-if="!selectedOption">{{ title }}</span>
            <span v-else>{{ selectedTitle }}</span>
        </div>
        <div v-if='!type' class="dropdown-menu" v-show="isDropdownOpen">
            <div class="option" :class="option.value === selectedOption ? 'active':''" v-for="option in options"
                 :key="option.value">
                <input type="radio" class="custom-radio" :value="option.value" :id="option.value+idname"
                       v-model="selectedOption" @click="dropDownClick">
                <label class="label-container" :for="option.value+idname">
                    <div class="option-label">{{ option.label }}</div>
                </label>
            </div>
        </div>
        <div v-if="type==='levels'" class="dropdown-menu"   v-show="isDropdownOpen">
            <div>
                <input type="radio" class="custom-radio" value="All" id="all"
                       v-model="selectedOption">
                <label class="label-container keyboard_option wide-option" for="all" @click="dropDownClick">
                    <div class="option-label">{{ $t('table.All') }}</div>
                </label>
            </div>
            <div class="keyboard">
                <div v-for="option in options"
                     :class="option.value === selectedOption ? 'active':''"
                     :key="option.value">
                    <input type="radio" class="custom-radio" :value="option.value" :id="option.value+idname"
                           v-model="selectedOption">
                    <label class="label-container keyboard_option" :for="option.value+idname"  @click="dropDownClick">
                        <div class="option-label">{{ option.label }}</div>
                    </label>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: "RadioDrop",
    data() {
        return {
            selectedOption: '',
            isDropdownOpen: false,
            distanceFiltersToBottom:250
        }
    },
    props: {
        options: {
            type: Array,
            default: () => ([])
        },
        title: {
            type: String,
            default: 'Title'
        },
        idname: {
            type: String,
            default: ''
        },
        type: {
            type: String,
            default: ''
        }
    },
    computed: {
        selectedTitle() {
            if (this.selectedOption) {
                let found = this.options.find((element) => element.value === this.selectedOption);
                return found?.label || this.$t('table.All');
            }
            return this.title
        }
    },
    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted () {
        window.removeEventListener('scroll', this.handleScroll);
    },
    mounted() {
        this.bus.on('clean', this.clean)
    },
    methods: {
        handleScroll () {
            var element = this.$refs.filter
            var domRect = element.getBoundingClientRect();
            this.distanceFiltersToBottom = window.innerHeight - domRect.bottom;
        },
        clean() {
            this.selectedOption = ''
        },
        onClickOutside() {
            this.isDropdownOpen = false;
        },
        dropDownClick() {
            console.log('dropDownClick')
            this.isDropdownOpen = !this.isDropdownOpen
        },
        closeDropdown() {
            this.isDropdownOpen = false;
        },
    },
    watch: {
        selectedOption() {
            this.$emit('setOption', this.selectedOption)
        }
    }
}
</script>

<style lang="scss">

</style>